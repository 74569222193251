<template>
  <div class="details">
    <div class="details-tip-bg" v-show="detailsShow"></div>
    <div class="details-tip" v-show="detailsShow">
      <div class="tip-close" @click="handleDetailsClose"  style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-item" v-if="list.list.length>0">
        <div class="item-title">
          <div class="title-text">比赛详情</div>
          <div class="title-time">比赛时间：{{obj.matchStart}} - {{obj.matchEnd}}</div>
        </div>
        <ul class="item-list">
          <li class="list-li" v-for="(item,idx) in list.list" :key="idx">
            <div class="li-name">{{item.name}}</div>
            <div class="li-item" v-for="(items,index) in item.list" :key="index">
              <div>{{index+1}}</div>
              <div>{{items.phone}}({{items.gameNickName}})</div>
            </div>
          </li>
        </ul>
        <div class="item-bnt">
          <!-- <div class="bnt-pass"  @click="handlePassOpen">取消报名</div> -->
          <div class="bnt-close" @click="handleDetailsClose">关闭</div>
        </div>
      </div>
      <div class="tip-no-data"  v-else>【您当前为待替补状态，若要上场比赛请联系裁判】</div>
    </div>
  </div>
</template>
<script>
import {
  encryptByDESModeCBCUtf8to64,
  decryptByDESModeCBC
} from "@/common/utils/des";
import {
  scheduleRecordInfo
} from "@/api/balance/match";
export default {
  name: "matchDetails",
  data() {
    return {
      detailsShow: false, // 查看详情弹窗状态
      passShow: false,
      list:{
        list:[]
      },
      obj: {}
    };
  },
  components: { },
  computed: {},
  methods: {
    handleDetailsClose() {
      this.detailsShow = false
    },
    handleDetailOpen(item) {
      this.detailsShow = true
      this.obj = item
      this.scheduleRecordInfo()
    },
    scheduleRecordInfo() {
      scheduleRecordInfo({scheduleId:this.obj.id}).then(res => {
        if(res.code == 200) {
          this.list = res.data
        }
      })

    },
  },
  created() {
  },
  mounted() {
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.details{
  color: #333333;
  .details-tip-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 44;

  }
  .details-tip{
    position: fixed;
    display: flex;
    top: 55%;
    left: 50%;
    background: #fff;
    transform: translate(-50%,-50%);
    z-index: 55;
    .tip-close{   
      position: absolute;
      width: 40px;
      height: 40px;
      top: -26px;
      right: -20px;
      z-index: 44;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .tip-no-data{
      width: 200px;
      padding: 30px;
      text-align: center;
      color: #666;

    }
    .tip-item{
      padding: 18px 30px 30px 30px;
      width: 551px;
      .item-title{
        display: flex;
        align-items: center;
        .title-text{
          color: #1A1A1A;
          font-size: 16px;
          font-weight: 400;
        }
        .title-time{
          font-size: 12px;
          color: #7D88A4;
          margin-left: 20px;
        }
      }
      .item-list{
        display: flex;
        flex-wrap: wrap;
        margin-top: 17px;
        .list-li{
          width: 264px;
          &:nth-child(2n){
            margin-left: 16px;
          }
          .li-name{
            background: #E8EBFE;
            font-size: 12px;
            color: #08166D;
            text-align: center;
            font-weight: bold;
            padding: 7px 0;
            box-shadow: 0px -4px 13px 0px rgba(204, 219, 230, 0.2);
            border-radius: 5px;
          }
          .li-item{
            font-size: 12px;
            display: flex;
            align-items: center;
            border-bottom:1px solid #E8EBFE ;
            padding: 10px 20px;
            &:last-child{
              border: none;
            }
            >div:nth-child(1){
              color: #4452FF;
              line-height: 23px;
            }
            >div:nth-child(2){
              color: #08166D;
              font-weight: 400;
              line-height: 23px;
              margin-left: 20px;
            }

          }
        }
      }
      .item-bnt{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        >div{
          width: 140px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          border-radius: 5px;
          border: 1px solid #5564F3;
          cursor: pointer;
          &.bnt-pass{
            color: #5564F3;
          }
          &.bnt-close{
            background: #5564F3;
            color: #fff;
            margin-left: 20px;
            box-shadow: 0px 10px 11px 0px rgba(85, 100, 243, 0.4);
          }

        }

      }
    }

  }
}
</style>
