<template>
  <div class="match-look">
    <img class="back" @click="bandleLookData" src="~@/assets/images/balance/game/back.png"/>
    <div class="search">
      <div class="search-left">
        <span >赛事名称：</span>
        <el-select v-model="value" placeholder="请选择" size="small">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div> 
      <div class="search-right">
        <div class="block">
          <span class="demonstration">时间查询：</span>
          <el-date-picker
            v-model="value1"
            size="small"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="right-bnt"  @click="handleSearch">查询</div>
      </div>
    </div>
    <div class="look-table">
        <el-table
          v-loading="expenseLoading"
          :data="tableData"
          border
          size="mini" 
          height="511px"
          :header-cell-style="{'text-align':'left','background': '#EBF1FE','color': '#000'}"
          :cell-style="{'text-align':'left'}"
          style="width: 100%">
          <el-table-column prop="name" label="赛事名称"  width="200px">
            <template slot-scope="scope" class="row" >
              <div class="li-name">
                {{scope.row.name}}  第{{scope.row.week}}周  第{{scope.row.rounds}}轮
              </div>
            </template> 
          </el-table-column>
          <el-table-column prop="matchTime" label="比赛日期" width="160px"> </el-table-column>
          <el-table-column prop="shopName" label="参赛门店"> </el-table-column>
          <el-table-column prop="teamOneId" label="对阵"  width="280px"> 
            <template slot-scope="scope" class="row" >
              <div class="li-name">
                <span  :class="{'name-red':scope.row.winTeamId == scope.row.teamOneId}">{{scope.row.teamOneName || '匹配队伍'}}</span> vs <span :class="{'name-red':scope.row.winTeamId == scope.row.teamTwoId}"  >{{scope.row.teamTwoName || '匹配队伍'}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="honor" label="荣誉"> 
            <template slot-scope="scope" class="row" >
              <div class="li-name">
                {{scope.row.honor == 1?'mvp':scope.row.honor == 2?'svp':'--'}} 
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="integral" label="积分"> </el-table-column>
          <el-table-column prop="shopName" label="是否加倍">
            <template slot-scope="scope" class="row" >
              <div class="li-name">
                <span :class="[scope.row.buyDouble ==2?'name-gree':'']">{{scope.row.buyDouble ==2?'加倍':'未加倍'}} </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="result" label="比赛结果"> 
            <template slot-scope="scope" class="row" >
              <div class="li-name">
                <span :class="[scope.row.scope ==3?'name-blu':'']"></span>{{scope.row.scope == 1?'胜利':scope.row.scope == 2?'失败':scope.row.scope == 3?'流局':'--'}} 
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="lists-page">
          <pagination
           
            :total="search.total"
            :page.sync="search.params.pageNum"
            :limit.sync="search.params.pageSize"
            @pagination="handleCurrentChange"
          />
        </div>
    </div>
  </div>
</template>
<script>
import {
  encryptByDESModeCBCUtf8to64,
  decryptByDESModeCBC
} from "@/common/utils/des";
import {
  history
} from "@/api/balance/match";
export default {
  name: "matchLook",
  data() {
    return {
      options:[],
      value: '',
      value1: '',
      expenseLoading: false,
      search: {
        is: false,
        val: "",
        params: {
          page: 1,
          pageSize: 9,
          isAsc: 1,
          name: ""
        },
        total: 0,
        list: []
      },
      tableData: []
    };
  },
  components: { },
  computed: {},
  methods: {
    // 搜索
    handleSearch() {
      this.search.params.pageNum = 1
      this.handleCurrentChange()
    },
    bandleLookData() {
      this.$router.go(-1)
    },
    handleCurrentChange() {
      history({lgUserId:sessionStorage.lgUserId}).then(res => {
        this.tableData = res.rows
        this.search.total = res.total
      })

    },
  },
  created() {
  },
  mounted() {
    this.handleCurrentChange()
  },
  destroyed() {}
};
</script>
<style lang="less">
@import "./look.less";
</style>
<style lang="less" scoped>
.lists-table{
  margin-top: 17px;
  /deep/.el-table{
    font-size: 12px;
    color: #666666;
    .el-table__body-wrapper{
      height: 324px !important;
      .el-table__body{
        width: 1067px !important;
      }
    }
  }
}
.lists-page{
  margin-top: 0px;
  /deep/.el-pagination{
    .el-pager{
      li{
        background: none;
        border: 1px solid #BDC8E4;
        color: #BDC8E4;
        &.active{
          border:none;
          color: #fff;
        }
      }
    }
    .btn-next{
      padding: 0 5px;
      background: none;
      border: 1px solid #A0ACCB;
      color: #A0ACCB;
    }
    .btn-prev{
      padding: 0 5px;
      background: none;
      border: 1px solid #A0ACCB;
      color: #A0ACCB;
    }
  }
}

</style>
