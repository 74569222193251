<template>
  <div class="match" >
    <!-- 这是电竞馆赛事 -->
    <img class="match-look" @click="bandleLookData" src="~@/assets/images/balance/match/look.png"/>
    <div class="match-content" v-if="matchList.length > 0">
      <div class="left">
        <div class="match-flex" ref="listImg">
          <div class="page-per page-item" @click="handlePagePer">
            <i class="el-icon-arrow-left" v-if="pageIdx!=1"></i>
            <i class="el-icon-arrow-left page-none" v-else></i>
          </div>
          <div class="flex-item"  v-for="(item,idx) in matchList" :key="idx" :style="{transform: `translate3d(-${heightShow}px, 0px,  0px)`}"  >
            <div class="flex-bg">
              <img v-if="item.imgUrl" :src="item.imgUrl"/>
              <!-- <img src="~@/assets/images/balance/match/bg.png"/> -->
              <div class="bg-pos">
                <img src="~@/assets/images/balance/match/imperial.png"/>
                <div class="flex-title">{{item.name}}第{{item.week}}周第{{item.rounds}}轮</div>
                <!-- <div class="flex-title">{{item.status}}</div> -->
                <div class="flex-bnt flex-add" v-if="item.status ==2 && item.isSignUp == 2"  @click="handleMatchData(item,idx)">立即报名</div>
                <div class="flex-bnt flex-pass" v-else-if="item.status == 2 && item.isSignUp == 1"  @click="handlePassOpen(item,idx)">取消报名</div>
                <div class="flex-bnt flex-details" v-else-if="item.status == 3"  @click="handleMatchData(item,idx)">
                  比赛详情</div>
                <div class="flex-bnt flex-details" v-else-if="item.status == 4 "  @click="handleMatchData(item,idx)">比赛详情</div>
                <div class="flex-bnt flex-details" v-else-if="item.status == 7 "  @click="handleMatchData(item,idx)">匹配成功</div>
                <div class="flex-bnt flex-add" v-else-if="item.status == 5 "  @click="handleMatchData(item,idx)">比赛开始</div>
                <div class="flex-bnt flex-end" v-else-if="item.status == 6 "  @click="handleMatchData(item,idx)">比赛结束</div>
              </div>
            </div>
            <div class="flex-tiems">
              <div>
                <div class="flex-time">
                  <div class="item-show">
                  </div>
                  <div>报名时间：
                    <span :class="[item.status==1?'time-text-item':item.status==6?'time-text-item':'time-text-bg']"  >
                    <!-- {{item.startTime}} -->
                    {{$utils.dateFormat(item.signUpstart, 'M.dd/hh:mm')}}-{{$utils.dateFormat(item. signUpEnd, 'M.dd hh:mm')}}
                    </span></div>
                </div>
                <div class="flex-time">
                  <div class="item-show">
                  </div>
                  <div>比赛时间：
                    <span :class="[item.status==1?' time-text-item':item.status==6?' time-text-item':'time-text-bg']">
                      <!-- {{item.endTime}} -->
                    {{$utils.dateFormat(item.matchStart, 'M.dd/hh:mm')}}-{{$utils.dateFormat(item.matchEnd, 'M.dd hh:mm')}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex-person ">
                <div v-if="item.isSignUp==1">报名成功</div>
                <div>报名人数</div>
                
                <div>{{item.signUpNumber || 0}}</div>
              </div>
            </div>
          </div>
          <div class="page-next page-item" @click="handlePageNext">
            <i class="el-icon-arrow-right" v-if="!pageNextShow"></i>
            <i class="el-icon-arrow-right page-none" v-else></i>
          </div>
        </div>
        <div class="match-img">
          <div class="img-tab">
            <div v-for="(item,idx) in imgTab" :class="{'img-show':imgIdx==idx}" :key="idx" @click="handleImgTabData(item,idx)">
              {{item.title}}
              <div :class="{'tab-show':imgIdx==idx}" ></div>
            </div>
           
          </div>
          <div class="img-content">
            <img :src="imgObj.imgUrl">
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right-content">
          <div  class="content-item">
            <div class="right-tab">
              <div class="tab-left">
                <div class="left-show">
                  <img src="~@/assets/images/balance/match/jackpot.png"/>
                  </div>
                <div class="left-name">奖金池</div>
              </div>
              <div class="tab-right" >
                <div v-for="(item,idx) in tabList" :class="{'right-show':tabIdx==idx}" :key="idx" @click="handleTabListData(item,idx)">{{item.name}}</div>
              </div>
            </div>
            <div class="right-money">
              <img src="~@/assets/images/balance/match/award.png" v-if="tabIdx == 1">
              <div class="money-month" v-if="tabIdx == 1">
                <div class="month-item month-one">
                  <div class="month-lgb">{{competitionObj.two.money || 0}}<span>币</span></div>
                  <div class="money-name">
                   <img src="~@/assets/images/balance/match/33.png"> 
                   <div>亚军</div>
                  </div>
                </div>
                <div class="month-item month-two">
                  <div class="month-lgb">{{competitionObj.one.money || 0}}<span>币</span></div>
                  <div class="money-name">
                   <img src="~@/assets/images/balance/match/11.png"> 
                   <div>冠军</div>
                  </div>
                </div>
                <div class="month-item month-three">
                  <div class="month-lgb">{{competitionObj.three.money || 0}}<span>币</span></div>
                  <div class="money-name">
                   <img src="~@/assets/images/balance/match/22.png"> 
                   <div>季军</div>
                  </div>
                </div>

              </div>
              <div class="money-week" v-else>
                <div>
                  {{competitionObj.week || 0}}<span>币</span>
                </div>
              </div>
            </div>
          </div>
          <div class="content-integration">
            <div class="integration-flex">
                <div class="integration-show">
                  <img src="~@/assets/images/balance/match/integration.png"/>
                </div>
                <div class="integration-name">积分榜</div>
            </div>
            <div class="integration-select">
              <div class="select-name">
                 <el-select v-model="shopVal" placeholder="请选择" @change="handleSelecShopName">
                  <el-option
                    v-for="item in ShopList"
                    :key="item.shopId"
                    :label="item.shopName"
                    :value="item.shopId">
                  </el-option>
                </el-select>
              </div>
              <div class="select-flex">
                <div :class="{'select-show':integrationIdx==idx}" @click="handleIntegrationTab(item,idx)" v-for="(item,idx) in integrationTab" :key="idx">{{item.name}}</div>
              </div>
            </div>
            <div class="integration-list">
              <div class="list-th">
                <div>排名</div>
                <div>会员</div>
                <div>积分</div>
              </div>
              <ul class="list-item" v-if="listData.length>0">
                <li class="list-td" v-for="(item,idx) in listData" :key="idx">
                  <div>
                    <img src="~@/assets/images/balance/match/1.png"  v-if="idx == 0"/>
                    <img src="~@/assets/images/balance/match/2.png"  v-else-if="idx == 1"/>
                    <img src="~@/assets/images/balance/match/3.png"  v-else-if="idx == 2"/>
                    <span v-else >{{idx+1}}</span>
                  </div>
                  <div>{{item.phone}}</div>
                  <div  :class="[item.ranking > 3?'':'td-show']">{{item.integral}}</div>
                </li>
              </ul>
              <div class="no-data" v-else>
                <div>暂无数据~</div>
              </div>
              <div class="list-my"  v-if="myObj.show">
                <div>您暂未有排名哦~</div>
              </div>
              <div class="list-my" v-else>
                <div >我的排名： <span>{{myObj.myRanking}}</span></div>
                <div>积分：<span>{{myObj.myScore}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-else>暂无赛事</div> -->
    <Nodata v-else :noWidth="'364px'"  :noHeight="'340px'" ></Nodata>
    <div class="lgb-tip-bg" v-show="lgbShow"></div>
    <div class="lgb-tip" v-show="lgbShow">
      <div class="tip-close" @click="handleLgbClose"  style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-item">
        <div class="item-flex">
          <img src="~@/assets/images/balance/match/tipIcon.png"/>
          <div class="flex-text">本次报名将收取20霖感币您的霖感币余额不足，请对您的账户进行充值。</div>
        </div>
        <div class="item-flex item-bnt">
          <div class="flex-back" @click="handleLgbClose">返回</div>
          <div class="flex-add" @click="handleLgbAddData">立即充值</div>
        </div>
      </div>
    </div>
    <div class="pass-tip-bg" v-show="passShow"></div>
    <div class="pass-tip" v-show="passShow">
      <div class="tip-close" @click="handPassClose"  style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-item">
        <div class="item-flex">
          <img src="~@/assets/images/balance/match/tipIcon.png"/>
          <div class="flex-text">取消报名将返还报名费至您的账户中确定要取消报名吗？</div>
        </div>
        <div class="item-flex item-bnt">
          <div class="flex-back"  @click="handlePassAdd">确定</div>
          <div class="flex-add" @click="handPassClose" >返回</div>
        </div>
      </div>
    </div>
    <!-- 未报名比赛 -->
    <div class="pass-tip-bg" v-show="noMatchShow"></div>
    <div class="pass-tip" v-show="noMatchShow">
      <div class="tip-close" @click="handleNonatchClose"  style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-item">
        <div class="item-flex">
          <div class="flex-text">【您未报名该场比赛】</div>
        </div>
      </div>
    </div>
    <addInfo ref="addInfo"></addInfo>
    <matchDetails  ref="matchDetails"></matchDetails>
    <matching ref="matching"></matching>
    <fight ref="fight"></fight>
  </div>
</template>
<script>
import TIM from 'tim-js-sdk'
import TIMUploadPlugin from 'tim-upload-plugin';
import {
  encryptByDESModeCBCUtf8to64,
  decryptByDESModeCBC
} from "@/common/utils/des";
import Nodata from "@/components/nodata";
import {
  nearCompetition,competitionAward,selectShop,shopRankIngList,cancelSignUp,selectCaption
} from "@/api/balance/match";
import {
  getAppData 
} from "@/api/balance/message";
import { getWalletInfo } from "@/api/userInfo";
import addInfo from "./commnt/addInfo";
import matchDetails from "./commnt/details";
import matching from "./commnt/matching";
import fight from "./commnt/fight";
export default {
  name: "match",
  data() {
    return {
      noMatchShow: false,
      pageNextShow: false,
      pageIdx: 1,
      passShow: false,
      ShopList: [],
      shopVal: null,
      lgbShow: false, // 霖感币不足提示弹窗
      myObj:{
        show: true,
      },
      matchList:[],
      listData:[],
      integrationTab:[
        {
          id: 1,
          name: '周积分'
        },
        {
          id: 2,
          name: '累计积分'
        },
      ],
      integrationIdx: 0,
      tabList: [
        {
          id: 1,
          name: '本轮周赛'
        },
        {
          id: 2,
          name: '本轮月赛'
        },
      ],
      tabIdx: 0,
      imgTab:[],
      imgObj: {},
      imgIdx: 0,
      heightShow: 0,
      rankVal: 1,
      competitionObj: {
        one: {},
        two: {},
        three: {}
      },
      lgbObj:{}, // 保霖感币余额
      passObj: {}, 
      appSDKApp: {},
      tim: null,
      openItem: {}, // 保存选中的赛程
    };
  },
  components: {addInfo,matchDetails,matching,fight,Nodata },
  computed: {},
  methods: {
    // 获取赛事规则数据
    handleSelectCaption() {
      selectCaption().then(res => {
        if(res.code == 200) {
          this.imgTab = res.data
          if(res.data.length> 0)  {
            this.imgObj =  res.data[0]
            
          }
        }
      });
    },
    // 取消报名
    handlePassAdd() {
      cancelSignUp({scheduleId: this.passObj.id,lgUserId:sessionStorage.lgUserId}).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '取消报名成功!',
            type: 'success'
          });
          this.handPassClose()
          this.handleNearCompetition()
        }else{
          this.$message.error(res.msg);

        }
      });

    },
    // 取消报名弹窗
    handlePassOpen(item,idx) {
      this.passShow = true
      this.passObj = item
    },
    handPassClose() {
      this.passShow = false
    },
    // 跳到充值页面
    handleLgbAddData() {
      this.$store.state.bntType = 5
      this.$router.push({path: '/userinfo',query:{ status: true}});

    },
    // 获取余额
    handleWalletInfo() {
      getWalletInfo({lgUserId:sessionStorage.lgUserId}).then(res => {
        if (res.code == 200) {
          if(res.data) {
            this.lgbObj = res.data; 
          }
        }
      });
    },
    // 判断霖感币够不够
    handleLgbPass(item) {
      if( this.lgbObj.lgMoney > 19){
        this.$refs.addInfo.handleInfoOpen(this.ShopList,item);
      }else{
        this.handleLgbOpen()
      }

    },
    handleOpenFight(item) {
      this.$refs.fight.handleFightOpen(item,5);
    },
    handleOpenItem() {
      this.$refs.fight.handleFightOpen(this.openItem,7);
      
    },
    handleNonatchClose() {
      this.noMatchShow = false
    },
    // 最近赛事点击事件 
    handleMatchData(item,idx) {
      this.openItem = item
      if(item.isSignUp==2) {
        this.noMatchShow = true
      }else{
        if(item.status == 1 || item.status == 2) {
          this.handleLgbPass(item)
          
        }else if (item.status == 4 && item.isSignUp == 1 ) {
          this.$refs.matchDetails.handleDetailOpen(item);
        }else if (item.status == 3 && item.isSignUp == 1 ) {
          this.$refs.matchDetails.handleDetailOpen(item);
        }else if (item.status == 5) {
          this.$refs.matching.handleMatchingOpen(item);
        }else if (item.status == 7) {
          this.$refs.fight.handleFightOpen(item,7);
      
        }else if (item.status == 6) {
          
          this.$refs.fight.handleFightOpen(item,6);
        }

      }
    },
    // 上一页
    handlePagePer() {
      let imgDow =  this.$refs.listImg
      let length = imgDow.offsetWidth/2
      this.pageIdx-- 
      this.pageNextShow = false
      if(this.pageIdx < 1 ) {
        this.pageIdx =1
      }
      if(this.heightShow>0) {
        this.heightShow = this.heightShow - (length+4)
      }else{
      }
    },
    // 下一页
    handlePageNext() {
      let imgDow =  this.$refs.listImg
      if(this.pageIdx  ==  this.matchList.length-1 ) { 
          this.heightShow = this.heightShow*1 
          this.pageIdx = this.matchList.length-1
          this.pageNextShow = true
      }else{
        this.heightShow = (this.heightShow+(imgDow.offsetWidth/2)+4 ) 
        this.pageIdx++ 
        if(this.pageIdx  ==  this.matchList.length-1 ) { 
          this.pageNextShow = true
          
        }

      }
    },
    bandleLookData() {
      this.$router.push('/matchLook')
    },
    handleIntegrationTab(item,idx) {
      this.integrationIdx = idx
      this.rankVal = item.id
      this.handleShopRankIngList()
    },
    handleTabListData(item,idx) {
      this.tabIdx = idx

    },
    handleImgTabData(item,idx) {
      this.imgIdx = idx
      this.imgObj = item

    },
    handleLgbClose() {
      this.lgbShow = false
    },
    handleLgbOpen() {
      this.lgbShow = true
    },
    // 积分排行榜
    handleShopRankIngList() {
      shopRankIngList({type: this.rankVal, shopId:this.shopVal,lgUserId:sessionStorage.lgUserId}).then(res => {
        if(res.code == 200) {
          var arr = Object.keys(res.data);
          if(arr.length == 0) {
            this.listData = []
            this.myObj.show = true
          }else{
            this.myObj.show = false
            this.listData = res.data.list
            this.myObj.myScore = res.data.myScore
            this.myObj.myRanking = res.data.myRanking
          }

        }
      })
    },
    handleSelecShopName(data) {
      this.shopVal = data
      this.handleShopRankIngList()
    },
    // 积分排行榜
    handleMyShopRankIngList() {
      shopRankIngList({type: this.rankVal,lgUserId:sessionStorage.lgUserId}).then(res => {
        if(res.code == 200) {
          var arr = Object.keys(res.data);
          if(arr.length == 0) {
            this.myObj.show = true
          }else{
            this.myObj.show = false
          }
        }
      })
    },
    // 门店
    handleSelectShop() {
      selectShop().then(res => {
        this.ShopList = res.data
      })
    },
    // 赛事奖金
    handleCompetitionAward(id,week) {
      competitionAward({competitionId: id,week: week}).then(res => {
        this.competitionObj = res.data
        let arr = JSON.parse(res.data.month) 
        for(let i in arr) {
          if(arr[i].id == 1) {
            this.competitionObj.one = arr[i]
          }else if(arr[i].id ==2)  {
            this.competitionObj.two = arr[i]

          }else{
            this.competitionObj.three = arr[i]

          }
        }

      })
    },
    // 最近赛事
    handleNearCompetition() {
      
      nearCompetition({lgUserId:sessionStorage.lgUserId}).then(res => {
        if(res.code == 200) {
          this.matchList = res.data.list
          this.handleCompetitionAward(res.data.competitionId,res.data.week)

        }
      })
    },
    /**
     * 获取签名
     */
    handleAppData() {
      let _this = this
      _this.tim =  _this.$store.state.tim 
      getAppData({lgUserId: sessionStorage.lgUserId}).then(res=>{
        if(res.code == 200) {
          _this.appSDKApp = JSON.parse(decryptByDESModeCBC(res.data.data));
          if(sessionStorage.IMShow) {
          }
      
        }
      })
    },
    // 获取会话消息列表
    handleMatchMessage(item) {
      let obj = JSON.parse(item.payload.text)
      if(obj.head == 'teamMatch') {
        this.handleOpenFight(obj);
        this.$refs.matching.handleMatchingClose();
      
      }else if(obj.head == 'memberSignIn') {
        this.$refs.matching.handleMatchingReady(obj);

      }else{
        this.$refs.matching.handleMarryShow(obj)
      }
    },
     /**
      * IM 登录 
      */
    login(item) {
      let lgUserId = sessionStorage.lgUserId
      let _this = this
      _this.appSDKApp = item
      _this.tim =  _this.$store.state.tim 
      let userSig = item.userSig
      let promise = _this.tim.login({userID: lgUserId, userSig: userSig});
        promise.then(function(imResponse) {
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
          }else{
            sessionStorage.IMShow = true
          }
        }).catch(function(imError) {
          console.warn('login error:', imError); // 登录失败的相关信息
        });
    },
    
  },
  created() {
    let info = JSON.parse(decryptByDESModeCBC(sessionStorage.secret));
    this.shopVal  = info.shopId
  },
  mounted() {
    if(sessionStorage.IMShow) {
      this.handleAppData()

    }
    this.handleWalletInfo()
    this.handleShopRankIngList()
    this.handleMyShopRankIngList()
    this.handleSelectShop()
    this.handleNearCompetition()
    this.handleSelectCaption()
  },
  destroyed() {}
};
</script>
<style lang="less">
@import "./index.less";
</style>
<style lang="less" scoped>

</style>
