<template>
  <div class="matching">
    <div class="matching-tip-bg" v-show="matchingShow"></div>
    <div class="matching-tip" v-show="matchingShow">
      <div class="tip-close" @click="handleMatchingClose"  style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-item" v-if="obj.members.length>0">
        <div class="item-title">
          <div class="title-flex">
            <div>周赛匹配</div>
            <div>匹配时段：{{obj.start}}-{{obj.end}}</div>
          </div>
          <div class="title-right">
            <div class="right-label">
              匹配时间:
              
            </div>
            <div class="right-time-none" v-if="!endTime">00:00:00</div>
            <div class="right-time-show" v-else>
              <count-down  class="right-time"
                :required='false'
                :minutesTxt="':'" :secondsTxt="''"
                v-on:start_callback="countDownS_cb()" 
                v-on:end_callback="countDownE_cb()" 
                :startTime="startTime" 
                :endTime="endTime" >
                </count-down>

            </div>
            <div v-if="!byShow" class="right-by right-by-none" @click="handleDoubleData">我要加倍</div>
            <div v-else  class="right-by right-by-show">您已加倍</div>
          </div>
        </div>
        <ul>
          <li v-for="(item,idx) in obj.members" :key="idx">
            <div class="head head-item">
              <img class="head-img" :src="item.headImg"/>
              <img class="head-icon" src="~@/assets/images/balance/match/star.png" v-if="idx == 0"/>
            </div>
            <div class="name">{{item.gameNickName}}</div>
            <div class="addBnt bnt-item add-bnt-show"  v-if="item.status == 1 && item.lgUserId != lgUserId">准备</div>
            <div class="addBnt bnt-item add-bnt-myshow" @click="handlePrepareData(item,idx)" v-else-if="item.status == 1 && item.lgUserId == lgUserId">准备</div>
            <div class="addBnt bnt-item add-bnt-none" v-else-if="item.status == 2">已准备</div>
          </li>
        </ul>
        <div class="item-add item-add-show" @click="handleMatchingStart"  v-if="!addBntShow && captain && marryShow == 4" >开始匹配</div>
        <div class="item-add item-add-pass" @click="handleOnMatchingStart"  v-else-if="addBntShow  && marryShow == 1 && captain" >取消匹配</div>
        <div class="item-add item-add-none" v-else-if="!addBntShow  && marryShow == 1 && !captain" >匹配中</div>
        <!-- <div class="item-add item-add-none" v-else-if="!addBntShow  && marryShow == 4 && !captain" >取消匹配</div> -->
        <div class="item-add item-add-none" v-else >开始匹配</div>
      </div>
      <div class="tip-no-data"  v-else>【您当前为待替补状态，若要上场比赛请联系裁判】</div>
    </div>

    <div class="pass-tip-bg" v-show="passShow"></div>
    <div class="pass-tip" v-show="passShow">
      <div class="tip-close" @click="handPassClose"  style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-item">
        <div class="item-flex">
          <img src="~@/assets/images/balance/match/tipIcon.png"/>
          <div class="flex-text">匹配时间过长，可能没有对手可匹配了您可以选择以下方式继续比赛</div>
        </div>
        <div class="item-flex item-bnt">
          <div class="flex-back"  @click="handlePassAdd">继续匹配</div>
          <div class="flex-add" @click="handlePassRank" >参加排位</div>
        </div>
      </div>
    </div>  
    <div class="double-tip-bg" v-show="doubleShow"></div>
    <div class="double-tip" v-show="doubleShow">
      <div class="tip-close" @click="handdoubleClose"  style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-item">
        <div class="item-flex">
          <img src="~@/assets/images/balance/match/double.png"/>
          <div class="flex-text">我要加倍一次</div>
        </div>
        <div class="item-text">本场比赛获胜后会使本人积分加倍</div>
        <div class="item-bnt">
          <div class="bnt-back"  @click="handleDoubleAdd">支付10霖感币</div>
        </div>
      </div>
    </div> 
    <div class="success-tip-bg" v-show="successShow"></div>
    <div class="success-tip" v-show="successShow">
      <div class="tip-close" @click="handdoubleClose"  style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-item">
        <div class="item-flex">
          <img src="~@/assets/images/balance/match/success.png"/>
          <div class="flex-text">恭喜你购买加倍卡成功已自动开始加倍</div>
        </div>
        <div class="item-bnt">
          <div class="bnt-back"  @click="handleSuccessBack">返回</div>
        </div>
      </div>
    </div> 
    <div class="lgb-tip-bg" v-show="lgbShow"></div>
    <div class="lgb-tip" v-show="lgbShow">
      <div class="tip-close" @click="handleLgbClose"  style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-item">
        <div class="item-flex">
          <img src="~@/assets/images/balance/match/tipIcon.png"/>
          <div class="flex-text">本次报名将收取20霖感币您的霖感币余额不足，请对您的账户进行充值。</div>
        </div>
        <div class="item-flex item-bnt">
          <div class="flex-back" @click="handleLgbClose">返回</div>
          <div class="flex-add" @click="handleLgbAddData">立即充值</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CountDown from 'vue2-countdown'
import {
  encryptByDESModeCBCUtf8to64,
  decryptByDESModeCBC
} from "@/common/utils/des";
import {
  selectTeamMember,startMatching,buyDouble,signIn,cancelMatching 
} from "@/api/balance/match";
import { getWalletInfo } from "@/api/userInfo";
export default {
  name: "matching",
  data() {
    return {
      lgbShow: false,
      matchingShow: false,
      passShow: false,
      doubleShow: false,
      successShow: false,
      obj:{
        start: '',
        end: '',
        members:[]
      },
      byShow: false,
      macthingObj:{},
      startTime: 0,
      endTime:0,
      lgbObj: {}, // 保存数据余额数据
      scheduleObj: {} , //保存赛程数据
      addBntShow: false,
      lgUserId: '',
      captain: false,
      marryShow: 4 , // 4未匹配||取消匹配 1匹配中 
    };
  },
  components: {CountDown },
  computed: {},
  methods: {
    handleMarryShow(item) {
      if(item.status == 1) {
        this.marryShow = item.status
        this.startTime = new Date().getTime()
        this.endTime = (new Date(item.endTime).getTime())
      }else{
        this.marryShow = item.status
        this.startTime =0
        this.endTime =0

      }
    },
    // 准备
    handlePrepareData(item,idx) {
      signIn({lgUserId:sessionStorage.lgUserId,status:2,scheduleId:this.scheduleObj.id}).then(res => {
        if (res.code == 200) {
          item.status = 2
          let arr = this.obj.members
          arr[idx] = item
          this.handleMembersList()
        }else{
          this.$message.error(res.msg);
        }
      });
    },
    handleLgbClose() {
      this.lgbShow = false
    },
    // 跳到充值页面
    handleLgbAddData() {
      this.$store.state.bntType = 5
      this.$router.push({path: '/userinfo',query:{ status: true}});

    },
    // 获取余额
    handleWalletInfo() {
      getWalletInfo({lgUserId:sessionStorage.lgUserId}).then(res => {
        if (res.code == 200) {
          if(res.data) {
            this.lgbObj = res.data; 
          }
        }
      });
    },
    // 加倍
    handleDoubleData() {
      this.doubleShow = true
    },
    countDownS_cb(){},
    countDownE_cb(){
      this.passShow = true
      this.addBntShow = false
    },
    // 取消匹配
    handleOnMatchingStart() {
      cancelMatching({teamId:this.macthingObj.teamId}).then(res => {
        if(res.code == 200) { 
          this.addBntShow = false
        }else{
          this.$message.error(res.msg);
        }
      })

    },
    handleMatchingStart() {
      startMatching({teamId:this.macthingObj.teamId}).then(res => {
        if(res.code == 200) { 
          this.addBntShow = true
          if(res.data.status == 2) {
            this.$parent.handleOpenItem()
            this.$parent.handleNearCompetition()
            this.handleMatchingClose()
          }
        }else{
          this.$message.error(res.msg);
        }
      })

    },
    selectTeamMember(item) {
      this.captain = false
      selectTeamMember({scheduleId:item.id,lgUserId:sessionStorage.lgUserId}).then(res => {
        if(res.code == 200) {
          this.obj = res.data
          let arr = this.obj.members
          this.startTime = new Date().getTime()
          this.endTime = (new Date(this.obj.matchEndTime).getTime())
          this.handleMembersList()
          
          if(arr[0].lgUserId == sessionStorage.lgUserId) {
            this.captain = true
          }
          arr.forEach(e => {
            if(sessionStorage.lgUserId == e.lgUserId) {
              this.macthingObj = e
              if(e.buyDouble==2) {
                this.byShow = true
              }else{
                this.byShow = false
              }
            }
          });

        }
      })
    },
    handlePassAdd() {
      this.handleMatchingStart()
      this.handPassClose()
    },
    handlePassRank() {
      qualifying({teamId:this.macthingObj.teamId}).then(res => {
        if(res.code == 200) {
          this.handPassClose()
          this.$parent.handleOpenItem()

        }else{
          this.$message.error(res.msg);

        }

      })
    },
    handPassClose() {
      this.passShow = false
    },
    handleMatchingClose() {
      this.matchingShow = false
    },
    handleMatchingOpen(item) {
      this.lgUserId = sessionStorage.lgUserId
      this.matchingShow = true
      this.scheduleObj = item
      this.selectTeamMember(item)
      this.handleWalletInfo()
    },
    handdoubleClose() {
      this.doubleShow = false
    },
    // 验证是否没有准备
    handleMembersList() {
      let arr = this.obj.members
      if(arr.findIndex(target=>target.status==1)==-1 ) {
        this.addBntShow = false
      }else{
        this.addBntShow = true
      }
    },
    handleMatchingReady(item) {
      for( let i in  this.obj.members) {
        if(this.obj.members[i].lgUserId == item.lgUserId) {
          this.obj.members[i].status = item.status
        }
      }
      this.handleMembersList()
      this.$forceUpdate();
    },
    handleDoubleAdd() {
      if( this.lgbObj.lgMoney > 19){
        buyDouble({lgUserId:sessionStorage.lgUserId}).then(res => {
          if(res.code == 200) {
            this.handdoubleClose()
            this.successShow = true
            this.byShow = true
          }else{
            this.byShow = false
            this.$message.error(res.msg);

          }
        })
      }else{
        // this.handleLgbOpen()
        this.handleLgbAddData()
      }

    },
    handleDoubleBack() {
      this.doubleShow = false
      
    },
    handleSuccessBack() {
      this.successShow = false

    },
    handleMessage(item) {
      if(item) {
        this.$parent.handleOpenFight(res.data);

      }
    },
  },
  created() {
  },
  mounted() {
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.matching{
  
  .matching-tip-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    z-index: 44;

  }
  .matching-tip{
    position: fixed;
    display: flex;
    top: 55%;
    left: 50%;
    background: #fff;
    transform: translate(-50%,-50%);
    z-index: 55;
    .tip-close{   
      position: absolute;
      width: 40px;
      height: 40px;
      top: -26px;
      right: -20px;
      z-index: 44;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .tip-no-data{
      width: 200px;
      padding: 30px;
      text-align: center;
      color: #666;

    }
    .tip-item{
      width: 650px;
      padding: 18px 30px 30px 30px;
      .item-title{
        display: flex;
        align-items: center;
        >div{
          display: flex;
          align-items: center;
          &.title-flex{
            >div:nth-child(1){
              color: #1A1A1A;
              font-size: 16px;
            }
            >div:nth-child(2){
              color: #7D88A4;
              font-size: 12px;
              margin-left: 19px;
              margin-top: 7px;
            }
          }
          &.title-right{
            margin-left: auto;
            font-size: 12px;
            .right-label{
              color: #08166D;
            }
            .right-time-none{
              margin-left: 5px;
              color: #08166D;
            }
            .right-time-show{
              margin-left: 5px;
              margin-top: 2px;
              .right-time{
                color: #08166D;
              }

            }
            .right-by{
              width: 72px;
              height: 26px;
              line-height: 26px;
              text-align: center;
              background: #FFFFFF;
              box-shadow: 0px 6px 15px 0px rgba(207, 190, 190, 0.3);
              border-radius: 13px;
              margin-left: 11px;
              cursor: pointer;
              &.right-by-show{
                color: #9BA1C4;
              }
              &.right-by-none{
                color: #FC5555;

              }
            }
          }
        }
      }
      ul{
        display: flex;
        margin-top: 30px;
        li{
          width: 120px;
          height: 150px;
          border-radius: 13px;
          text-align: center;
          font-size: 12px;
          margin-right: 15px;
          &:last-child{
            margin-right: 0;
            background: linear-gradient(31deg, #ECFDF9 0%, #F5FDFC 100%);
            .head{
              background: #27EEC3;
            }
            .bnt-item{
              box-shadow: 0px 6px 15px 0px rgba(117, 184, 171, 0.2);
            }
          }
          &:nth-child(1){
            background: linear-gradient(31deg, #FDECE4 0%, #FEF5F0 100%);
            .head{
              background: #FC9152;
            }
            .bnt-item{
              box-shadow: 0px 6px 15px 0px rgba(198, 149, 120, 0.2)
            }

          }
          &:nth-child(2){
            background: linear-gradient(31deg, #EEF2FE 0%, #F3F6FF 100%);
            .head{
              background: #2779F8;
            }
            .bnt-item{
              box-shadow: 0px 6px 15px 0px rgba(128, 139, 167, 0.2);
            }
          }
          &:nth-child(3){
            background: linear-gradient(31deg, #EAF7FE 0%, #F2FAFE 100%);
            .head{
              background: #59BFFF;
            }
            .bnt-item{
              box-shadow: 0px 6px 15px 0px rgba(141, 170, 185, 0.2);
            }
            
          }
          &:nth-child(4){
            background: linear-gradient(31deg, #FDF0F0 1%, #FFF9F9 100%);
            .head{
              background: #FC5555;
            }
            .bnt-item{
              box-shadow: 0px 6px 15px 0px rgba(208, 153, 153, 0.2);
            }
            
          }
          .head-item{
            position: relative;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            margin: 27px auto 0;
            >.head-img{
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .head-icon{
              position: absolute;
              width: 23px;
              height: 21px;
              right: -4px;
              top: -10px;
              z-index: 33;
            }
          }
          .name{
            color: #08166D;
            // line-height: 61px;
            margin-top: 5px;
          }
          .addBnt{
            width: 98px;
            height: 26px;
            line-height: 26px;
            background: #FFFFFF;
            // box-shadow: 0px 6px 15px 0px rgba(198, 149, 120, 0.2);
            border-radius: 13px;
            margin: 20px auto 0;
            // color: #9BA1C4;
            &.add-bnt-show{
              color: #FC5555;
              cursor: no-drop;

            }
            &.add-bnt-myshow{
              color: #FC5555;
              cursor: pointer;

            }
            &.add-bnt-none{
              color: #9BA1C4;
              cursor: no-drop;

            }

          }
        }
      }
      .item-add{
        width: 120px;
        height: 36px;
        line-height: 36px;
        border-radius: 5px;
        text-align: center;
        margin: 40px auto 0;
        &.item-add-show{
          background: #5564F3;
          box-shadow: 0px 6px 15px 0px rgba(85, 100, 243, 0.3);
          cursor: pointer;

        }
        &.item-add-pass{
          background: #E39C04;
          box-shadow: 0px 6px 15px 0px rgba(227, 156, 4, 0.3);
          cursor: pointer;

        }
        &.item-add-none{
          background: #D2D4DB;
          cursor: no-drop;

        }

      }
    }

  }
  .pass-tip-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    z-index: 66;
  }
  .pass-tip{
    position: fixed;
    display: flex;
    top: 55%;
    left: 50%;
    background: #fff;
    transform: translate(-50%,-50%);
    z-index: 77;
    .tip-close{   
      position: absolute;
      width: 40px;
      height: 40px;
      top: -26px;
      right: -20px;
      z-index: 44;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .tip-item{
      margin: 35px;
      .item-flex{
        display: flex;
        justify-content: center;
        >img{
          width: 16px;
          height: 16px;
        }
        &.item-bnt{
          margin-top: 20px;
          cursor: pointer;
          >div{
            width: 80px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            border-radius: 8px;
            font-size: 12px;

          }
        }
        .flex-text{
          max-width: 260px;
          font-size: 14px;
          margin-left: 13px;
          color: #000;
          text-align: center;
          line-height: 24px;
          font-weight: 500;
        }
        .flex-back{
          border: 1px solid #3C76FA;
          color: #3C76FA;

        }
        .flex-add{
          background: #3C76FA;
          color: #fff;
          margin-left: 28px;

        }

      }
    }

  }
  .double-tip-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    z-index: 66;
  }
  .double-tip{
    position: fixed;
    display: flex;
    top: 55%;
    left: 50%;
    background: #fff;
    transform: translate(-50%,-50%);
    z-index: 77;
    .tip-close{   
      position: absolute;
      width: 40px;
      height: 40px;
      top: -26px;
      right: -20px;
      z-index: 44;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .tip-item{
      margin: 35px;
      .item-flex{
        display: flex;
        justify-content: center;
        >img{
          width: 16px;
          height: 16px;
        }
        &.item-bnt{
          margin-top: 20px;
          cursor: pointer;
          >div{
            width: 80px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            border-radius: 8px;
            font-size: 12px;

          }
        }
        .flex-text{
          max-width: 260px;
          font-size: 16px;
          margin-left: 13px;
          color: #000;
          text-align: center;
          line-height: 24px;
          font-weight: 600;
        }
        .flex-back{
          border: 1px solid #3C76FA;
          color: #3C76FA;

        }
        .flex-add{
          background: #3C76FA;
          color: #fff;
          margin-left: 28px;

        }

      }
      .item-text{
        font-size: 12px;
        color: #666;
        line-height: 20px;
      }
      .item-bnt{
        display: flex;
        justify-content: center;
        .bnt-back{
          width: 110px;
          height: 34px;
          line-height: 34px;
          text-align: center;
          background: #FD2929;
          border-radius: 8px;
          color: #fff;
          margin-top: 32px;
          font-size: 12px;
          box-shadow: 0 6px 15px 0 rgba(253, 41, 41, 0.4);
          cursor: pointer;
        }
      }
    }

  }
  .success-tip-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    z-index: 66;
  }
  .success-tip{
    position: fixed;
    display: flex;
    top: 55%;
    left: 50%;
    background: #fff;
    transform: translate(-50%,-50%);
    z-index: 77;
    .tip-close{   
      position: absolute;
      width: 40px;
      height: 40px;
      top: -26px;
      right: -20px;
      z-index: 44;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .tip-item{
      margin: 30px;
      .item-flex{
        display: flex;
        justify-content: center;
        >img{
          width: 16px;
          height: 16px;
        }
        &.item-bnt{
          margin-top: 20px;
          cursor: pointer;
          >div{
            width: 80px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            border-radius: 8px;
            font-size: 12px;
          }
        }
        .flex-text{
          max-width: 180px;
          font-size: 16px;
          margin-left: 13px;
          color: #000;
          text-align: center;
          line-height: 24px;
          // font-weight: 600;
        }
        .flex-back{
          border: 1px solid #3C76FA;
          color: #3C76FA;

        }
        .flex-add{
          background: #3C76FA;
          color: #fff;
          margin-left: 28px;

        }

      }
      .item-bnt{
        display: flex;
        justify-content: center;
        .bnt-back{
          width: 110px;
          height: 34px;
          line-height: 34px;
          text-align: center;
          background: #3C76FA;
          border-radius: 8px;
          color: #fff;
          margin-top: 32px;
          font-size: 12px;
          box-shadow: 0 6px 15px 0 rgba(60, 118, 250, 0.3);
          cursor: pointer;
        }
      }
    }

  }
  .lgb-tip-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 88;
  }
  .lgb-tip{
    position: fixed;
    display: flex;
    top: 55%;
    left: 50%;
    background: #fff;
    transform: translate(-50%,-50%);
    z-index: 99;
    .tip-close{   
      position: absolute;
      width: 40px;
      height: 40px;
      top: -26px;
      right: -20px;
      z-index: 99;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .tip-item{
      margin: 35px;
      .item-flex{
        display: flex;
        >img{
          width: 16px;
          height: 16px;
        }
        &.item-bnt{
          margin-top: 20px;
          >div{
            width: 80px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            border-radius: 8px;
            font-size: 12px;
            cursor: pointer;
          }
        }
        .flex-text{
          max-width: 180px;
          font-size: 14px;
          margin-left: 13px;
          color: #000;
          text-align: center;
          line-height: 24px;
          font-weight: 500;
        }
        .flex-back{
          border: 1px solid #3C76FA;
          color: #3C76FA;
        }
        .flex-add{
          background: #3C76FA;
          color: #fff;
          margin-left: auto;

        }

      }
    }

  }
}
</style>
