<template>
  <div class="fight">
    <div class="fight-tip-bg" v-show="fightShow"></div>
    <div class="fight-tip-show" v-show="fightShow">
      <div class="tip-close" @click="handleFightClose"  style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="fight-tip" v-if="obj.team1.members.length > 0 || obj.team2.members.length > 0">
        <img class="fight-text" v-if="showStuats== 6" src="~@/assets/images/balance/match/text.png">
        <img class="fight-text" v-else src="~@/assets/images/balance/match/text2.png">
        
        <div class="tip-item">
          <div class="fight-left">
            <ul>
              <li v-for="(item,idx) in obj.team1.members" :key="idx">
                <div class="fight-headImg">
                  <img :src="item.headImg"/>
                  <img class="head-vp mvp" v-if="item.lgUserId == obj.mvp" src="~@/assets/images/balance/match/mvp.png"/>
                  <img class="head-vp svp" v-if="item.lgUserId == obj.svp" src="~@/assets/images/balance/match/svp.png"/>
                </div>
                <div class="fight-name">{{item.gameNickName}}</div>
              </li>
            </ul>
            <div class="fight-team">{{obj.team1.teamName}}</div>
          </div>
          <div  class="fight-right">
            <div class="fight-team">{{obj.team2.teamName}}</div>
            <ul>
              <li v-for="(item,idx) in obj.team2.members" :key="idx">
                <div class="fight-headImg">
                  <img :src="item.headImg"/>
                  <img class="head-vp mvp" v-if="item.lgUserId == obj.mvp" src="~@/assets/images/balance/match/mvp.png"/>
                  <img class="head-vp svp" v-if="item.lgUserId == obj.svp" src="~@/assets/images/balance/match/svp.png"/>
                </div>
                <div class="fight-name">{{item.gameNickName}}</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="fight-info">
          <div  v-if="obj.room" class="fight-info-romm">
            <div class="info-item" >比赛服务器：{{obj.room.serverName || '还未创建'}}</div>
            <div class="info-item">房间名称：{{obj.room.name || '***'}}</div>
            <div class="info-item" >房间密码：{{obj.room.password  || '***'}}</div>

          </div>
          <div v-else  class="fight-info-none">
          </div>

          <div class="info-bnt" v-if="(showStuats == 5 && captain) || (showStuats == 7 && captain)">
            <el-upload
              class="avatar-demo"
              action="/zd-api/web/client/league/uploadResult"
              :data="{recordId:recordId}"
              :show-file-list="false"
              :headers="headers"
              name="img"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <div class="demo-tip"  v-if="imageUrl">
                <img :src="imageUrl" class="avatar">
                <div class="tip-show">
                  重新上传
                </div>
              </div>
              <div v-else class="bnt-item">上传截图</div>
            </el-upload>
            <div class="info-tip">若上传截图与比赛结果不一致，将取消该玩家比赛资格</div>
          </div>
          <div class="info-bnt" v-else>
            <img :src="obj.img"/>
          </div>
        </div>

      </div>
      <div v-else class="tip-no-data">
        【您当前为待替补状态，很遗憾比赛已经结束,未上场比赛请期待下一季赛事!】
      </div>
    </div>

  </div>             
</template>
<script>
import {
  encryptByDESModeCBCUtf8to64,
  decryptByDESModeCBC
} from "@/common/utils/des";
import {
  selectRecordResult
} from "@/api/balance/match";
export default {
  name: "fight",
  data() {
    return {
      fightShow: false,
      obj:{
        team1:{
          members:[]
        },
        team2:{
          members:[]

        },
        room: {},

      },
      imageUrl: '',
      showStuats:5,
      recordId: '',
      headers: {
        secret: ""
      },
      captain: false
    };
  },
  components: { },
  computed: {},
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);

    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 4MB!');
      }
      return isLt2M;
    },
    handleFightClose() {
      this.fightShow = false
    },
    handleFightOpen(item,status) {
      this.fightShow = true
      this.captain = false
      this.showStuats = status
      this.headers.secret = sessionStorage.secret
      this.obj={
        team1:{
          members:[]
        },
        team2:{
          members:[]

        },
        room: {},

      }
      if(status==5) {
        this.obj = item
        let arr = this.obj.team1.members
        let arr1 = this.obj.team2.members
        if(arr[0].lgUserId == sessionStorage.lgUserId) {
          this.captain = true
        }
        if(arr1[0].lgUserId == sessionStorage.lgUserId) {
          this.captain = true
        }
      }else{
        this.handleSelectRecordResult(item)
      }
      
    },
    handleSelectRecordResult(item) {
      selectRecordResult({scheduleId: item.id,lgUserId:sessionStorage.lgUserId}).then(res => {
        if(res.code == 200){
          this.obj = res.data
          this.recordId = res.data.recordId
          this.imageUrl = res.data.img
          let arr = this.obj.team1.members
          let arr1 = this.obj.team2.members
          if(arr[0].lgUserId == sessionStorage.lgUserId) {
            this.captain = true
          }
          if(arr1[0].lgUserId == sessionStorage.lgUserId) {
            this.captain = true
          }

          
        }
      })
    },
  },
  created() {
  },
  mounted() {
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.fight{
  .fight-tip-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    z-index: 44;
  }
  .fight-tip-show{
    position: fixed;
    display: flex;
    top: 48%;
    left: 50%;
    background: #fff;
    transform: translate(-50%,-50%);
    z-index: 55;
    .tip-close{   
      position: absolute;
      width: 40px;
      height: 40px;
      top: -26px;
      right: -20px;
      z-index: 44;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }

  }
  .tip-no-data{
    width: 200px;
    padding: 40px;
    text-align: center;
    color: #666;
  }
  .fight-tip{
    width: 800px;
    height: 485px;
    background: url('~@/assets/images/balance/match/vs.png');
    .fight-text{
      position: absolute;
      width: 163px;
      height: 33px;
      top: 45%;
      left: 41%;
      z-index: 56;
    }
    .tip-item{
      display: flex;
      width: 100%;
      // align-items: center;
      padding: 18px 30px 30px 30px;
      .fight-left{
        display: flex;
        margin-left: 51px;
        ul{
          li{
            margin-top: 28px;
            &:first-child{
            }
            .fight-headImg{
              position: relative;
              width: 32px;
              height: 32px;
              background: #45DDFE;
              border-radius: 50%;
              margin: auto;
              .head-vp{
                position:absolute;
                top: -11px;
                right: -19px;
                z-index: 33;
                &.mvp{
                  width: 32px;
                  height: 30px;

                }
                &.svp{
                  width: 38px;
                  height: 30px;

                }
              }
              >img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }

            }
            .fight-name{
              color: #AFDEFB;
              font-size: 12px;
              text-align: center;
              margin-top: 10px;
            }
          }
        }
        .fight-team{
          width: 2px;
          color: #6BC5FD;
          font-size: 22px;
          margin-top: 32px;
          margin-left: 35px;
          font-family: monospace;
          font-weight: bold;

        } 
      }
      .fight-right{
        display: flex;
        margin-right: 51px;
        margin-left: auto;
        ul{
          li{
            margin-top: 28px;
            .fight-headImg{
              position: relative;
              width: 32px;
              height: 32px;
              background: #FC5555;
              border-radius: 50%;
              margin: auto;
              .head-vp{
                position:absolute;
                top: -11px;
                right: -19px;
                z-index: 33;
                &.mvp{
                  width: 32px;
                  height: 30px;

                }
                &.svp{
                  width: 38px;
                  height: 30px;

                }
              }
              >img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }

            }
            .fight-name{
              color: #FFC7C7;
              font-size: 12px;
              text-align: center;
              margin-top: 10px;
            }
          }
        }
        .fight-team{
          width: 2px;
          color: #FC8585;
          font-size: 22px;
          margin-top: 32px;
          margin-right: 65px;
          font-family: monospace;
          font-weight: bold;

        } 
      }
    }
    .fight-info{
      position: absolute;
      min-height: 195px;
      bottom: -14%;
      left: 50%;
      transform: translate(-50%,-50%);
      text-align: center;
      font-size: 12px;
      z-index: 56;
      .fight-info-romm{
        width: 130px;
        min-height: 64px;
      }
      .fight-info-none{
        width: 130px;
        min-height: 64px;

      }

      .info-item{
        margin-top: 8px;
      }
      .info-bnt{
        margin-top: 20px;
        .avatar-demo{
          .demo-tip{
            position: relative;
            width: 150px;
            height: 100px;
            img{
              width: 100%;
              height: 100%;
            }
            .tip-show{
              position: absolute;
              width: 100%;
              height: 100%;
              line-height: 100px;
              text-align: center;
              font-size: 14px;
              background: rgba(0,0,0,0.6);
              color: #fff;
              left: 0;
              top: 0;
              z-index: 33;
            }
          }
        }
        .bnt-item{
          width: 120px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          color: #fff;
          background: #FF0000;
          border-radius: 5px;
          margin: 30px auto 30px; 
          box-shadow: 0px 6px 15px 0 #97304A;
          cursor: pointer;
        }
      }
      .info-tip{
        color: #FC9E9E;
        margin-top: 11px;
      }
    }

  }

}
</style>
