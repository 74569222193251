<template>
  <div class="info">
    <div class="info-tip-bg" v-show="infoShow"></div>
    <div class="info-tip" v-show="infoShow">
      <div class="tip-close" @click="handleInfoClose"  style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-item">
        <div class="item-title">报名信息</div>
        <el-form  label-width="110px" :label-position="labelPosition" :model="formInline" class="demo-form-inline">
          <div>
            <el-form-item label="参赛游戏昵称">
              <el-input v-model="formInline.user" placeholder="请输入游戏昵称"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="参赛门店">
              <el-select v-model="formInline.shopVal" placeholder="请选择" @change="handleSelecShopName">
                <el-option
                  v-for="item in shopList"
                  :key="item.shopId"
                  :label="item.shopName"
                  :value="item.shopId">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="推荐店员账号">
              <el-input v-model="formInline.recommend" placeholder="请输入推荐人电话号码"></el-input>
            </el-form-item>
          </div>
          <div class="form-bnt" @click="handleAddInfoData">
            确认提交
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  encryptByDESModeCBCUtf8to64,
  decryptByDESModeCBC
} from "@/common/utils/des";
import {
  selectShop,getSignUp
} from "@/api/balance/match";
export default {
  name: "info",
  data() {
    return {
      labelPosition: 'right',
      infoShow: false, // 报名信息弹窗状态
      formInline: {
        user: '',
        shopVal: '',
        shopName: ''
      },
      shopList:[],
      matchObj:{}
    };
  },
  components: { },
  computed: {},
  methods: {
    handleSelecShopName(e) {
      for(let i in this.shopList) {
        if(e == this.shopList[i].shopId) {
          this.formInline.shopName = this.shopList[i].shopName
        }
      }
    },
    handleAddInfoData() {
      if(!this.formInline.user) {
        this.$message.error('参赛游戏昵称不能为空~');
        return

      }
      if(!this.formInline.shopVal) {
        this.$message.error('请选择门店名称~');
        return
      }
      let params = {
        scheduleId: this.matchObj.id,
        gameNickName: this.formInline.user,
        lgUserId: sessionStorage.lgUserId,
        shopId: this.formInline.shopVal,
        shopName: this.formInline.shopName,
        phone: this.formInline.recommend,
      }
      getSignUp(params).then(res => {
        if(res.code == 200) {
          this.$message({
            message: '报名成功!',
            type: 'success'
          });
          this.handleInfoClose()
          this.$parent.handleNearCompetition();
        }else{
          this.$message.error(res.msg);
        }
      })

    },
    handleInfoOpen(item,data) {
      this.shopList = item
      this.matchObj = data
      this.formInline = {
        user: '',
        shopVal: '',
        shopName: '',
      }
      this.infoShow = true
    },
    handleInfoClose() {
      this.infoShow = false
    },
  },
  created() {
  },
  mounted() {
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.info{
  color: #333333;
  .info-tip-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 44;

  }
  .info-tip{
    position: fixed;
    display: flex;
    top: 55%;
    left: 50%;
    background: #fff;
    transform: translate(-50%,-50%);
    z-index: 55;
    .tip-close{   
      position: absolute;
      width: 40px;
      height: 40px;
      top: -26px;
      right: -20px;
      z-index: 44;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .tip-item{
      padding: 23px;
      .item-title{
        text-align: center;
        font-size: 16px;
        color: #1A1A1A;
        font-weight: bold;
        margin-bottom: 25px;
      }
      .form-bnt{
        width: 88px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #5564F3;
        color: #fff;
        border-radius: 5px;
        box-shadow: 0px 8px 16px #C6CBFB;
        font-size: 14px;
        margin:  25px auto ;
        cursor: pointer;
      }
    }

  }
}
</style>
